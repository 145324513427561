@charset "utf-8";
/* CSS Document */


/* .visual-animate
--------------------- */

.visual-animate {
	height: 100%;
	//overflow: hidden;
	opacity: 0;
	position: relative;
	width: 100%;
	z-index: 1;
}

/* .visual-animate__inner */
.visual-animate__inner {
	height: 100%;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}

/* .visual-animate__bg */
.visual-animate__bg {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	width: 100%;
}
.visual-animate__bg__layer {
	display: table;
	height: 100%;
	width: 100%;
}
.visual-animate__bg__layer__inner {
	display: table-cell;
	height: 100%;
	text-align: center;
	vertical-align: middle;
	width: 100%;
}

.visual-animate {
	ul.visual-animate__slider {
		/* .slide */
		&.slide {
			display: flex;
			height: 100%;
			left: 0;
			position: relative;
			top: 0;
			> li {
				height: 100%;
				position: relative;
				width: 100%;
			}
		}

		/* .fade */
		&.fade {
			height: 100%;
			width: 100%;
			> li {
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
		
		figure {
			display: none;
		}
	}
} 

/* .btn */
.visual-animate__btn-prev,
.visual-animate__btn-next {
	height: 100%;
	position: absolute;
	top: 0;
	z-index: 3;
	width: 24px;
	@media screen and (max-width: 767px) {
		width: 10vw;
	}
}
.visual-animate__btn-prev,
.visual-animate__btn-next {
	> div {
		align-items: center;
		display: flex;
		height: 100%;
		width: 100%;
	}
	span {
		cursor: pointer;
		display: inline-block;
		width: 100%;
	}
	img {
		opacity: 0.5;
		transform: translate(0, 0);
		transition: .1s ease-in-out;
		width: 100%;
	}
	span:hover img {
		opacity: 1;
	}
}
.visual-animate__btn-prev {
	left: 24px;
	span:hover img {
		transform: translate(-10px, 0);
		@media screen and (max-width: 767px) {
			transform: translate(0, 0);
		}
	}
}
.visual-animate__btn-next {
	right: 24px;
	span:hover img {
		transform: translate(10px, 0);
		@media screen and (max-width: 767px) {
			transform: translate(0, 0);
		}
	}
}

/* ul.visual-animate__dot */
ul.visual-animate__dot {
	display: flex;
	justify-content: center;
	padding: 10px 0 0;
	text-align: center;
	width: 100%;
	li {
		background: #ccc;
		border-radius: 10px;
		cursor: pointer;
		height: 20px;
		margin-right: 10px;
		width: 20px;
		&:last-child {
			margin-right: 0;
		}
		&.current {
			background: #39c;
		}
	}
}

/* .visual-animate__num */
.visual-animate__num {
	bottom: 0;
	position: absolute;
	text-align: center;
	width: 100%;
	span {
		background: rgba(255,255,255,0.8);
		display: inline-block;
		padding: 2px 5px;
	}
}

/* .visual-animate__overlay */
.visual-animate__overlay {
	background: rgba(255,255,255,0.8);
	height: 100%;
	position: absolute;
	top: 0;
	width: -webkit-calc((100% - 600px) / 2);
	width:         calc((100% - 600px) / 2);
	z-index: 2;
	@media only screen and (max-width: 1000px) {
		display: none;
	}
}
.visual-animate__overlay--left {
	left: 0;
}
.visual-animate__overlay--right {
	right: 0;
}

/* deactivate */
.deactivate {
	.visual-animate {
		height: 100%!important;
		ul {
			height: 100%;
			width: 100%!important;
			li {
				display: none!important;
				height: 100%!important;
			}
		}
	}
	.visual-animate__inner {
		height: 100%;
	}
	.visual-animate__btn-prev,
	.visual-animate__btn-next {
		display: none;
	}
}
